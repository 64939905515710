@import url("https://fonts.googleapis.com/css?family=Alice");
:root {
  --font-style: "Alice";
  /* --font-style: "Arial Black", sans-serif  ; */
  /* --font-style: "Gill Sans	", sans-serif;  */
}

.title-camp,
.subtitle {
  font-family: var(--font-style);
  font-weight: bolder;
  color: white;
  text-align: center;
  font-size: 5rem;
  align-items: center;
  justify-content: center;
}

.subtitle {
  font-size: 2.5rem;
}

.title-center {
  position: relative;
  font-family: var(--font-style);
  color: white;
  padding: 20px 0;
  text-align: center;
  font-size: 3rem;
  align-items: center;
  justify-content: center;
}

.display-flex-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10em;
  row-gap: 1.5rem;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button-container > .p-button {
  font-size: 1.5rem;
  font-family: "Arial", sans-serif;
  align-items: center !important;
  justify-content: center !important;
  padding: auto 5rem;
  margin: 10px !important;
  border-radius: 10px;
  width: 200px;
  height: 60px;
}

#button-login {
  background-color: rgb(89, 124, 230);
  margin-right: 30px;
}

#button-login:hover {
  background-color: rgb(42, 77, 183);
  opacity: 0.8;
}

.sponsor-logo-circle {
  border-radius: 50%;
  /* border-color: black; */
  border: 50px border black;
  width: 100px;
  height: 100px;
  margin: 10px;
}

.top-div {
  position: relative;
  left: 0;
  z-index: 1;
}

.center-logos {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-link {
  text-decoration: none;
  color: black;
  width: auto;
  height: auto;
  display: inherit;
  flex-wrap: inherit;
  align-items: inherit;
  justify-content: inherit;
  /* margin: 10px; */
}

.sponsor-logo-text {
  font-size: 1.5rem;
  font-family: "Arial", sans-serif;
  color: black;
  text-align: center;
  /* margin: 10px; */
}

.sponsor-logo-text p {
  color: white;
  font-size: 24px;
  font-weight: 24px;
  font-family: "Verdana", sans-serif;
}

.hero-div {
  position: relative;
  display: grid;
  place-items: center;
  top: 0;
  z-index: 1;
  background: none;
  padding: 20px;
  text-align: center;
  font-size: 2rem;
  font-family: "Arial", sans-serif;
  color: white;
  height: inherit;
}

/*respove the issue of the flexbox not being responsive*/
/*mobile*/
/* @media only screen and (max-width: 600px) {
  .sponsor-logo-circle {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin: 50px;
  }
} */

.camp-logo {
  width: 300px;
  height: 300px;
}

.register-count-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0;
  margin-bottom: 10px;
}

.register-count-div > * {
  font-size: 2.5rem;
  color: aliceblue;
  padding: 0;
  margin: 0;
  font-family: var(--font-style);
}

@media only screen and (max-width: 850px) {
  .sponsor-logo-circle {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    padding: 0;
    /* margin: 25px 10px; */
  }

  .sponsor-logo-text {
    margin: 25px 0;
    /* margin: 10px; */
  }

  .display-flex-responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 5em;
  }

  .button-container {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
  .button-container > .p-button {
    font-size: 16px;
    align-items: center !important;
    justify-content: center !important;
    padding: auto 5rem;
    margin: 10px !important;
    width: 150px;
    height: 40px;
  }
  .title-camp {
    font-size: 42px;
  }
  .subtitle {
    font-size: 24px;
  }

  .register-count-div > * {
    font-size: 2rem;
  }

  .p-image.p-component.camp-logo img {
    width: 10px !important;
    height: 10px !important;
    margin: 10px;
    background-color: aqua !important;
  }

  .hero-div {
    display: block;
  }
  .hero-contents {
    position: relative;
    top: 10%;
  }
  .button-container {
    margin-top: 1.25rem;
  }

  .button-container button.p-button.p-component {
    width: 250px;
    height: 50px;
  }

  .button-container button.p-button.p-component > * {
    font-size: 1.1em;
  }
}
