section.google-form-radio {
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

section.google-form-radio div {
  display: flex;
  flex-direction: row;
}

section.google-form-radio input[type="radio"] {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
  background-color: brown;
  color: violet;
  width: 1rem;
}

div.google-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  background-image: linear-gradient(37deg, rgb(32, 218, 233), rgb(40, 21, 236));
}

form.google-form {
  background-color: #f8f9fa;
  max-width: 700px;
  border-radius: 2rem;
  padding: 1rem 2rem;
}

form.google-form > p {
  font-size: 20px;
  text-align: justify;
}

form.google-form h1 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
}

section.google-form-text {
  margin: 0.5rem 0;
}

section.google-form-text textarea {
  width: 100%;
  height: 3rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
}

button.google-form-button {
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  margin: 0.5rem 0;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add transition */
}

button.google-form-button:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slight scaling for a pop effect */
}

button.google-form-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  form.google-form {
    border-radius: 0;
  }
  div.google-form-container {
    padding: 0 0;
  }
}
